import React from 'react';

import { useWhoami } from '../hooks/auth';
import { MinimalLayout } from '../layouts/MainLayout';
import { Button, styled, Typography } from '@mui/material';
import { PermIdentity } from '@mui/icons-material';
import getConfig from 'next/config';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  maxWidth: '800px',
  textAlign: 'center',
  paddingTop: '5rem',
  margin: '0 auto'
});
const { publicRuntimeConfig } = getConfig();
export function AccessDeniedView() {
  const { data } = useWhoami();
  return (
    <MinimalLayout>
      <Container>
        <Typography variant='h2'>
          {data?.authenticated ? "You do not have permission's to access this area" : 'You are not logged in'}
        </Typography>
        <div>
          <Button
            startIcon={<PermIdentity />}
            size='large'
            variant='contained'
            color='primary'
            href={`${publicRuntimeConfig.AUTH_URL}`}
          >
            You can log in here
          </Button>
        </div>
      </Container>
    </MinimalLayout>
  );
}
