import React, { ReactNode } from 'react';
import { useWhoami } from './hooks/auth';
import { UserWithWarehouses } from '@schibsted-distribution/parcel-registration-api-client';
import { useGoogleMapsApi } from './hooks/googleApi';

export type whoAmIDataType = {
  authenticated: boolean;
  role?: string;
  user?: UserWithWarehouses;
};

type GlobalContextType = {
  isLoading: boolean;
  isError: boolean;
  whoAmIData: whoAmIDataType;
};

const defaultGlobalContext = {
  isLoading: false,
  isError: false,
  whoAmIData: {
    authenticated: false
  }
};

const GlobalContext = React.createContext<GlobalContextType>(defaultGlobalContext);

const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const { isLoading: isGoogleMapsApiLoading } = useGoogleMapsApi();
  const { data: whoAmIData, isLoading: isWhoAmILoading, isError } = useWhoami();

  const isLoading = isWhoAmILoading || isGoogleMapsApiLoading;

  return whoAmIData ? (
    <GlobalContext.Provider value={{ whoAmIData, isLoading, isError }}>{children}</GlobalContext.Provider>
  ) : null;
};

export { GlobalContext, GlobalProvider };
