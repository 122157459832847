import App from 'next/app';
import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CacheProvider, EmotionCache } from '@emotion/react';
import theme from '../theme';
import createEmotionCache from '../theme/createEmotionCache';
import { AppContext } from 'next/dist/pages/_app';
import { ReactNode, useContext } from 'react';
import { UserRole } from '@schibsted-distribution/parcel-registration-api-client';
import { AccessDeniedView } from '../views/AccessDeniedView';
import { MinimalLayout } from '../layouts/MainLayout';
import { GlobalContext, GlobalProvider } from '../globalContext';

const clientSideEmotionCache = createEmotionCache();
const queryClient = new QueryClient();

type AppPropsWithErr = AppProps & { err: any; emotionCache: EmotionCache };

class MyApp extends App<AppPropsWithErr, {}, {}> {
  static defaultProps = { emotionCache: clientSideEmotionCache };

  // Added to disable Static Site optimization (missing API_URL from runtimeConfig)
  static async getInitialProps({ Component, ctx }: AppContext) {
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    return { pageProps };
  }

  render() {
    let { Component, emotionCache, pageProps, err } = this.props;
    return (
      <CacheProvider value={emotionCache}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              <DisableSSR>
                <GlobalProvider>
                  <HasAccessToPage>
                    <Component {...pageProps} err={err} />
                  </HasAccessToPage>
                </GlobalProvider>
              </DisableSSR>
              <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
            </LocalizationProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </CacheProvider>
    );
  }
}
function DisableSSR({ children }: { children: ReactNode }) {
  return <div suppressHydrationWarning>{typeof window === 'undefined' ? null : children}</div>;
}

function HasAccessToPage({ children }: { children: ReactNode }) {
  const { isLoading, isError, whoAmIData } = useContext(GlobalContext);

  if (isLoading) {
    return (
      <MinimalLayout>
        <div>
          <h1>Loading...</h1>
        </div>
      </MinimalLayout>
    );
  }
  if (isError) {
    return (
      <MinimalLayout>
        <div>
          <h1>Ups somethings goes wrong...</h1>
        </div>
      </MinimalLayout>
    );
  }
  if (whoAmIData?.authenticated === true) {
    if (whoAmIData.role === UserRole.ADMIN || whoAmIData.role === UserRole.LOCAL_ADMIN) {
      return <>{children}</>;
    }
  }

  return <AccessDeniedView />;
}
export default MyApp;
