import * as React from 'react';

import {
  AccountCircle,
  CalendarViewMonth,
  Inbox,
  Logout,
  PendingActions,
  Person,
  QrCode2,
  Warehouse,
  PinDrop
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from '@mui/material';

import NextLink from 'next/link';
import { ReactNode } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useWhoami } from '../hooks/auth';

const drawerWidth = 240;

const MenuLink = ({ href, name, children }: { href: string; name: string; children: ReactNode }) => {
  const router = useRouter();
  const currentPageHref = router.pathname;
  const isActive = currentPageHref === href || currentPageHref.startsWith(href + '/');
  return (
    <NextLink href={href} passHref>
      <ListItem key={name} component={Link} sx={{ color: isActive ? 'secondary.main' : 'primary.main' }}>
        <ListItemIcon>{children}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </NextLink>
  );
};

export function MainLayout({ children }: { children: ReactNode }) {
  const { data } = useWhoami();
  const { publicRuntimeConfig } = getConfig();
  const userRole = data && data.authenticated ? data.role.toLowerCase().replace('_', ' ') : '';

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position='fixed' sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
            <AccountCircle />
          </Box>
          {data && data.authenticated && (
            <>
              <Box sx={{ margin: '8px 15px 4px 4px' }}>
                <Typography variant='subtitle1'>
                  {data.user ? `${data.user.firstName} ${data.user.lastName} (${userRole})` : userRole}
                </Typography>
              </Box>
            </>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton href={`${publicRuntimeConfig.AUTH_URL}/logout`}>
              <Logout style={{ fill: '#fff' }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant='permanent'
        anchor='left'
      >
        <Toolbar sx={{ margin: '0 auto' }}>
          <NextLink href='/' passHref>
            <Typography variant='h6' noWrap component='div' sx={{ cursor: 'pointer' }}>
              Parcel registration
            </Typography>
          </NextLink>
        </Toolbar>
        <Divider />
        <List>
          <MenuLink name='Presets' href='/presets'>
            <Inbox />
          </MenuLink>
          <MenuLink name='Users' href='/users'>
            <Person />
          </MenuLink>
          <MenuLink name='Warehouses' href='/warehouses'>
            <Warehouse />
          </MenuLink>
          <MenuLink name='Bookings' href='/bookings'>
            <CalendarViewMonth />
          </MenuLink>
          <MenuLink name='QR wizard' href='/qr-wizard'>
            <QrCode2 />
          </MenuLink>
          <MenuLink name='Events' href='/events'>
            <PendingActions />
          </MenuLink>
          <MenuLink name='Addresses' href='/addresses'>
            <PinDrop />
          </MenuLink>
        </List>
        <Divider />
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <Toolbar />
        <Grid container spacing={1}>
          {children}
        </Grid>
      </Box>
    </Box>
  );
}

export function MinimalLayout({ children }: { children: ReactNode }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position='fixed'>
        <Toolbar>
          <Typography variant='h6' noWrap component='div'>
            Parcel registations
          </Typography>
        </Toolbar>
      </AppBar>

      <Box component='main' sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <Toolbar />
        <Grid container spacing={1}>
          {children}
        </Grid>
      </Box>
    </Box>
  );
}
