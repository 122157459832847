import getConfig from 'next/config';
import { useEffect, useState } from 'react';

const { publicRuntimeConfig } = getConfig();
const { GOOGLE_API_KEY } = publicRuntimeConfig;

export const useGoogleMapsApi = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'google-maps-api';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&v=weekly&language=nb`;
    script.async = true;
    script.onload = () => {
      setIsLoading(false);
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return {
    isLoading
  };
};
